






import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ImageCover extends Vue {
    @Prop({required: true})
    src!: string;

    @Prop({required: true})
    alt!: string
}
